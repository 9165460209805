import { path } from 'kitchen/router'
import * as Segment from './segment'

export const INDEX = `/`

export const AUTH = path(Segment.AUTH)
export const COMPANIES = path(Segment.COMPANIES)
export const COMPANIES_ID = path(Segment.COMPANIES, Segment.COMPANY_ID)
export const COMPANIES_BILLING_DETAILS = path(
  Segment.COMPANIES,
  Segment.COMPANY_ID,
  Segment.BILLING,
  Segment.BILLING_ID
)
export const COMPLIANCE_ALERTS = path(Segment.COMPLIANCE_ALERTS)
export const COMPLIANCE_ALERTS_ID = path(
  Segment.COMPLIANCE_ALERTS,
  Segment.COMPLIANCE_ALERT_ID
)

export const ISSUES = path(Segment.ISSUES)

export const PAYRUNS = path(Segment.PAYRUNS)
export const PAYRUNS_ID = path(Segment.PAYRUNS, Segment.PAYRUN_ID)

export const PAYOUTS = path(Segment.PAYOUTS)

export const TOPUPS = path(Segment.TOPUPS)
export const TOPUP_ID = path(Segment.TOPUPS, Segment.TOPUP_ID)

export const ACTIVITIES = path(Segment.ACTIVITIES)
export const TOOLS = path(Segment.TOOLS)

export const SETTINGS = path(Segment.SETTINGS)
export const SETTINGS_AGENTS = path(Segment.SETTINGS, Segment.AGENTS)
